.welcomeTitle img {
    display: block;
    width: 9rem;
    margin: auto;
    margin-top: 2rem;
}

@media (max-width: 640px) {
    .welcomeTitle img {
        width: 7rem;
        margin-top: 1rem;
    }
}