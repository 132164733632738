:root {
    --purple: #a94fe6;
    --purple-dark: #433063;
    --white: #fff;
    --orange: #f48965;
    --gray-light: #f7f6f9;

    --primary: var(--purple);
}

.wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    height: 3.8rem;
    padding: 0 1.25rem;
    border: 2px solid transparent;
    border-radius: 999px;
    font-weight: 700;
    font-size: 100%;
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: 500ms all;
    box-sizing: content-box;
}
@media (min-width: 640px) {
    .wrapper {
        height: 4.3rem;
        padding: 0 4rem;
    }
}

.wrapper.primary {
    border-color: var(--primary);
    color: var(--white);
    background-color: var(--primary);
}
.wrapper.primary:hover {
    color: var(--primary);
    background-color: transparent;
}
.wrapper.primary.outlined {
    color: var(--primary);
}
.wrapper.primary.outlined:hover {
    color: var(--white);
    background-color: var(--primary);
}
.wrapper.primary.active {
    color: var(--white);
    background-color: var(--primary);
    pointer-events: none;
}

.wrapper.secondary {
    border-color: var(--orange);
    color: var(--gray-light);
    background-color: var(--orange);
}
.wrapper.secondary:hover {
    color: var(--orange);
    background-color: transparent;
}
.wrapper.secondary.outlined {
    color: var(--orange);
}
.wrapper.secondary.outlined:hover {
    color: var(--gray-light);
    background-color: var(--orange);
}

.wrapper.tertiary {
    border-color: var(--purple-dark);
    color: var(--white);
    background-color: var(--purple-dark);
}
.wrapper.tertiary.outlined {
    color: var(--purple-dark);
}
.wrapper.tertiary.outlined:hover {
    color: var(--white);
    background-color: var(--purple-dark);
}

.wrapper.whiteBtn {
    color: var(--primary);
    background-color: white;
}
.wrapper.whiteBtn:hover {
    border-color: white;
    color: white;
    background-color: transparent;
}

.wrapper.outlined {
    background-color: transparent;
}

.wrapper.small {
    height: 2.35rem;
    padding: 0 0.75rem;
}
@media (min-width: 640px) {
    .wrapper.small {
        height: 3.0147058824rem;
        padding: 0 1.5rem;
    }
}

.wrapper.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.wrapper .loader {
    display: none;
}

.wrapper.loading {
    pointer-events: none;
}

.wrapper.loading .content {
    visibility: hidden;
}

.wrapper.loading .loader {
    display: block;
    position: absolute;
}

.wrapper.link {
    background-color: transparent;
    text-decoration: underline;
    font-weight: normal;
}
