.react-select {
    width: 100%;
    text-align: left;
    font-weight: 600;
    margin: 0 auto;
}
.react-select > div {
    height: 3.3rem;
}
.react-select.hasError > .react-select__control {
    border-color: var(--orange-dark);
}
.react-select.hasError > .react-select__control .react-select__indicator {
    /* background-color: var(--orange-dark); */
}

.pl .react-select > div {
    height: 3.8rem !important;
}

.pl .react-select__control {
    height: 3.8rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.pl .react-select.hasError .react-select__control {
    border-color: var(--orange-dark) !important;
}

.pl .react-select__indicator {
    margin-right: 8px;
}

.react-select-pl .react-select__option {
    text-align: center;
    color: black;
    font-size: 1.2rem;
}
.react-select-pl .react-select__option:hover {
    background-color: rgb(242 233 248);
    color: black;
}

.react-select-pl .react-select__option--is-selected {
    background-color: rgb(242 233 248);
    color: black;
}