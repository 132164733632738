
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: 'Poppins', sans-serif !important;
  border-color: rgb(169 79 230) !important;
  border-width: 1px !important;
  font-size: 14px !important;

  -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}

.react-datepicker__header {
  background-color: #f2e9f8 !important;
}

h2.react-datepicker__current-month {
  padding-top: 6px;
  padding-bottom: 6px;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker-popper {
  z-index: 51 !important;
}
.react-datepicker-popper .react-datepicker__triangle {
  stroke: rgb(169 79 230) !important;
  fill: #f2e9f8 !important;
  color: #f2e9f8 !important;
}

.react-datepicker__close-icon {
  padding: 0 8px 0 0 !important;
}

.input-size-medium .react-datepicker__close-icon {
  padding: 0 13px 0 0 !important;
}

.react-datepicker__close-icon::after {
  background-color: #f2e9f8 !important;
  color: #433063 !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 19px !important;
}

.react-datepicker__header__dropdown--select {
  padding: 5px 0 !important;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  background-color: transparent;
  border: none;
}

.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  border: 1px solid rgb(169 79 230) !important;
  padding: 3px 5px;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 8px;
}

.react-datepicker__close-icon--disabled {
  display: none;
}