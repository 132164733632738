.dataValue {
    width: 100%;
    border: 2px solid silver;
    border-radius: 999px;
    font-weight: 600;
    font-size: 1.125rem;
    color: #777;
    background-color: #f7f6f9;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 640px) {
        padding: 0.6rem 0;
    }
    @media (min-width: 640px) {
        min-height: 2.75rem;
    }
}

.maxWidth {
    max-width: 20rem;
}
