.inputStyles {
    width: 100%;
    height: 3rem;
    padding: 0 0 0 2.5rem;
    border: 2px solid var(--primary);
    border-radius: 999px;
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--gray-dark);
    background-color: var(--gray-light);
    outline: none;
    display: flex;
    justify-content: center;
}
.inputStyles.hasError {
    background-color: var(--orange-light);
    border-color: var(--orange-dark) !important;
}
.inputStyles.hasSuffix {
    padding-right: 3.5rem;
    text-align: right;
}
.inputStyles:disabled {
    width: 100%;
    max-width: 20rem;
    min-height: 2.75rem;
    margin: 0 auto;
    border: 2px solid var(--gray-medium);
    font-weight: 500;
    color: #777777 !important;
}
.inputStyles::placeholder {
    font-weight: 500;
}

.pl .inputStyles {
    padding-left: 0;
    height: 3.5rem;
    background-color: var(--white);
    font-size: 1.2rem;
    font-weight: 500 !important;
}
.pl .inputStyles:disabled {
    max-width: none !important;
    border: 2px solid rgb(222,216,222) !important;
    background-color: rgb(245,238,249) !important;
}

@supports (-webkit-hyphens: none) {
    .pl .inputStyles:disabled {
        color: #222 !important;
    }
}

.inputWrap {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 20rem;
    color: var(--gray-dark);
}
@media (min-width: 640px) {
    .inputWrap:hover svg {
        display: block;
    }
}

.pl .inputWrap {
    max-width: inherit;
}
.pl .inputWrap:hover svg {
    display: none;
}

.pl .inputWrap input {
    text-align: center;
}

.inputLoadingWrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.inputLoader {
    position: absolute;
    top: 8px;
    z-index: 2;
}

.editIcon {
    position: absolute;
    display: none;
    left: 8px;
    bottom: 10px;
    margin-top: 5px;
    width: 2rem;
}

.inputSuffix {
    display: flex;
    align-items: center;
    height: 3.0147058824rem;
    position: absolute;
    top: 0;
    left: calc(100% - 3.5rem);
    padding: 0.59rem 0.25rem;
    font-size: 1.125rem;
    line-height: 0;
}

.formItemError {
    color: var(--orange-dark);
    font-size: 0.9rem;
    margin-top: -17px;
}

.pl .formItemError {
    margin-top: 0;
}

.textInputWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1.5rem;
}

.pl .textInputWrap {
    gap: 0.5rem;
}

.prefix {
    width: 95px;
    margin-right: 0.25rem;
}

.phone {
    margin: 0;
}

.boxValue {
    width: 100%;
    padding: 0.6rem 1.5rem;
    max-width: 20rem;
    min-height: 2.75rem;
    margin: 0 auto;
    border: 2px solid var(--gray-medium);
    border-radius: 999px;
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--gray-dim);
    background-color: var(--gray-light);
}

.phoneInput {
    gap: 0.25rem;
    max-width: 20rem;
    margin: 0 auto;
}
