.boxWrap {
    border-radius: 2rem;
    display: flex;
    background-color: var(--purple-lightest);
    flex-direction: column;
    gap: 30px;
    align-items: center;
    margin-top: 20px;
    padding: 3rem 4.2rem 3rem 4.2rem;
    transition: 500ms opacity;
    position: relative;
}

@media (max-width: 640px) {
    .boxWrap {
        padding: 2rem 1rem;
        gap: 0px;
    }
}

.pl .boxWrap {
    background-color: rgb(242 233 248);
}