.button {
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--primary);
    border-radius: 999px;
    color: var(--white);
    position: relative;
    cursor: pointer;
    transition: 500ms all;

    @media (min-width: 640px) {
        &:not(.small) {
            width: 1.625rem;
            height: 1.625rem;

            svg {
                width: 0.75rem;
            }
        }
    }

    &.isActive {
        background: var(--primary);
    }

    svg {
        width: 0.875rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .checkIcon {
        opacity: 0;
        transition: 500ms all;
    }

    .checkIconVisible {
        opacity: 1;
    }
}

.toggler {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 1rem;
}

.positiveToggler {
    height: calc(100% + 4px);
    transform: translateX(-2px);
    border-radius: 500px;
    border: 2px solid var(--primary);
}
