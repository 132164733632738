.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.description {
    font-size: 1.25em !important;
    max-width: 60rem;
    font-weight: 500;
    line-height: 1.8;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 640px) {
    .description {
        margin: 0 auto;
    }
}

.navbar {
    background-color: var(--white);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    width: calc(100% - 5rem);
    height: 6.25rem;
    padding: 0 2.5rem;
    position: inherit;
    top: 0;
    z-index: 10;
}

@media (max-width: 640px) {
    .navbar {
        height: 5rem;
    }
}

.logo {
    position: relative;
    top: 1px;
    width: 90px;
    height: auto;
    cursor: pointer;
}

.headline {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 1rem 10px 0 10px;
}

@media (max-width: 640px) {
    .headline {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 2rem;
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: inherit;
    background-color: var(--white);
    box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.06);
    height: auto;
}
@media (min-width: 480px) {
    .footer {
        height: 6.25rem;
    }
}
@media (max-width: 479px) {
    .footer {
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.footer .container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
}
@media (max-width: 479px) {
    .footer .container {
        flex-direction: column;
    }
}

.emailIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;
    border-radius: 2.25rem;
    color: var(--white);
    background-color: var(--purple-dark);
}
.emailIcon svg {
    width: 1.125rem;
}

.emailWrap {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.link {
    text-decoration: none;
    font-weight: 700;
}

.leavingModalFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 100px;
    column-gap: 30px;
}
@media (max-width: 950px) {
    .leavingModalFooter {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        gap: 15px;
    }
}

.headerContent {
    margin: auto;
    padding-right: 25%;
}

.pl .wrapper {
    font-size: 1.2rem;
    font-weight: 500;
}
@media (max-width: 479px) {
    .pl .wrapper {
        height: 2.8rem;
    }
}

.pl .wrapper.smaller {
    padding: 0.6rem 0.8rem;
    height: auto;
    font-size: 1rem;
}