.processingContent {
    min-height: calc(70vh - 11rem);
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    @media (max-width: 640px) {
        padding: 0 10px;
        & > p {
            font-size: 1em;
            margin: 10px 0;
        }
    }
}

.bankIdErrorWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -10vh; 
    @media (min-width: 1800px) { 
        top: -20vh; 
    }
}

.bankIdErrorImg {
    height: 350px;
    @media (max-width: 640px) {
        height: 250px;
    }
    @media (max-width: 440px) {
        height: 150px;
    }
}