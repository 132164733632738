.confirmationWrap {
    display: grid;
    width: 70%;
    margin: 0 auto;
    gap: 55px 15px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        & > .inputLabel > .myLabel:nth-child(even) {
            margin-top: 100px;
        }
    }
}

.confirmationItemCentered {
    & > h3 {
        margin: -55px 0;
        position: relative;
        top: 55px;
        @media (max-width: 640px) {
            margin: 0;
            top: 0;
        }
    }
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
}

.bankAccount {
    display: flex;
    width: 120%;
    gap: 10px;
    grid-column-start: 1;
    grid-column-end: 3;
    position: relative;
    left: -10%;
    @media (max-width: 640px) {
        flex-direction: column;
    }
}

.checkboxWrap {
    width: 100%;
    display: flex;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 3;
    gap: 1rem;
    margin: 0 auto;
    justify-content: center;
    @media (max-width: 640px) {
        margin: 50px auto;
    }
}

.pepLabel {
    width: auto;
    cursor: pointer;
}

.issueDateError .react-date-picker__wrapper {
    background-color: var(--orange-light) !important;
    border-color: var(--orange-dark) !important;
    color: var(--orange-dark) !important;
}

.newBankId {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        flex-shrink: 0;
        width: 1.625rem;
        height: 1.625rem;
        margin-right: 1rem;
    }
    .text {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .decoration {
        text-decoration: underline;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .decoration:hover {
        text-decoration: none;
    }
}

.successWrap {
    height: calc(48vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    @media (max-width: 640px) {
        font-size: 0.9em;
        gap: 20px;
    }
}

.errorBox {
    padding: 3rem 4.2rem 3rem 4.2rem;
    border-radius: 2rem;
    text-align: center;
    min-width: 60vw;
    font-size: 1.2em;
    background-color: var(--orange-light);
    
    @media (min-width: 640px) {
        padding: 3rem;
    }
    @media (max-width: 640px) {
        min-width: unset;
        margin-top: 150px;
    }
}
