.darkBG {
    width: 120vw;
    height: 100%;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    opacity: 0.98;
    background-color: var(--gray-light);
}

.modalCentered {
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal {
    background-color: var(--purple-lightest);
    z-index: 100;
    width: 50vw;
    border-radius: 2rem;
    min-width: 66vw;
    max-height: 75vh;
    padding: 1.5rem 3rem 4rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    line-height: 1.8;
    text-align: center;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 640px) {
        min-width: 80vw;
        padding: 3rem 10px;
    }
    @media (max-width: 1200px) {
        width: 80vw;
    }
}

.modalHeader {
    @media (max-width: 640px) {
        margin-top: 20px;
    }
}

.heading {
    font-weight: 730;
    text-align: center;
    margin: 10px auto 0 auto;
    width: 90%;
}

.headingM {
    font-size: 1.5em;
}

.headingXl {
    font-size: 2.5em;
    @media (max-width: 640px) {
        font-size: 1.5em;
    }
}

.modalContent {
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
    margin: 0 auto;
}

.closeBtn {
    position: absolute;
    top: 1rem;
    right: 0;
    transform: translateX(-50%);
    background-color: var(--purple-lightest);

    @media (min-width: 640px) {
        top: 2.625rem;
        right: 2.625rem;
        left: auto;
        transform: none;
    }
    z-index: 1000;
}

.modalFooter {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 30px auto 0 auto;
    gap: 10px;
    & > h4,
    p {
        margin: 0;
    }
    & > a:link {
        text-decoration: none;
        color: inherit;
    }
}

.bankIdModalContent {
    & > p {
        width: 90%;
        margin: 0 auto;
    }
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.leavingModalContent {
    font-size: 1.2em;
    padding: 10px;
    width: 90%;
    @media (max-width: 640px) {
        font-size: 0.9em;
    }
}

.orderedListItem::before {
    background-color: var(--primary);
    content: '';
    display: inline-flex;
    flex-shrink: 0;
    height: 0.75rem;
    justify-content: center;
    margin-right: 1rem;
    transform: translateY(0.55rem);
    width: 0.75rem;
    border-radius: 0.75rem;
    position: relative;
    top: -8px;
}
