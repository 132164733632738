.main {
    flex: 1;
    overflow: auto;
    text-align: center;
    height: 100%;
    gap: 1.3rem;
    padding: 24px 0 36px;
    margin: inherit;
}
.main p, .main h3 {
    color: var(--purple-dark);
}
.main .notVisible {
    display: none !important;
}

.description {
    font-size: 1.25em !important;
    max-width: 60rem;
    font-weight: 500;
    line-height: 1.8;
    margin-left: auto;
    margin-right: auto;
    color: var(--purple-dark);
}
@media (max-width: 640px) {
    .description {
        margin: 0 auto;
    }
}

.navbar {
    background-color: white;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    width: calc(100% - 5rem);
    height: 6.25rem;
    padding: 0 2.5rem;
    position: fixed;
    top: 0;
    z-index: 10;
}

.logo {
    position: relative;
    top: -2px;
    width: 90px;
    height: auto;
    cursor: pointer;
}

.headline {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 5rem 10px 0 10px;
}
@media (max-width: 640px) {
    .headline {
        margin-top: 3rem;
        font-size: 1.5rem;
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    background-color: white;
    box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.06);
    height: 6.25rem;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer .container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.emailIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;
    border-radius: 2.25rem;
    color: var(--white);
    background-color: var(--purple-dark);

    svg {
        width: 1.125rem;
    }
}

.emailWrap {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.link {
    text-decoration: none;
    font-weight: 700;
}

.leavingModalFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 100px;
    column-gap: 30px;
}

@media (max-width: 950px) {
    .leavingModalFooter {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        gap: 15px;
    }
}
