.clientPortalListContent {
    width: 75%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 640px) {
        width: 100%;
        margin-bottom: 0;
    }
}

.clientPortalImportant {
    display: flex;
    gap: 13px;
    align-items: flex-start;
    justify-content: flex-start;
    text-wrap: nowrap;
    text-align: left;
    padding-left: calc(2rem + 15px);
    b {
        font-size: 1.1em;
        position: relative;
        top: 3px;
    }
    @media (max-width: 640px) {
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    @media (max-width: 1000px) {
        text-wrap: wrap;
    }
}

.redirectButton {
    margin-top: 30px;
    @media (max-width: 640px) {
        margin-top: 10px;
    }
}
