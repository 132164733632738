.documentsWrap {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 640px) {
        width: 100%;
        flex-direction: column;
        gap: 0;
        p {
            font-size: 0.8em;
        }
        img {
            width: 15px;
        }
    }
}

.documentsDownload {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
    @media (max-width: 640px) {
        gap: 0;
        text-align: unset;
        flex-direction: column;
    }
}

.downloadLink {
    color: var(--primary);
    font-weight: bolder;
    text-decoration: none;
}

.formListContent {
    width: 75%;
    margin-bottom: 20px;
    @media (max-width: 640px) {
        width: 100%;
        margin-bottom: 0;
    }
}
