.tooltip {
  display: inline-block;
  position: relative;
}

.tooltipWrapper {
  position: absolute;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  display: none;
  margin-bottom: -27px;
  bottom: 100%;
  max-width: 17.625rem;
  text-align: left;
  font-weight: 500;

  @media (min-width: 40em) {
    width: 31.25rem;
  }
}

.tooltipWrapper.left-1\/2 {
  left: 50%;
}

.tooltipWrapper.right-0 {
  right: 0;
}

.tooltipWrapper.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(-50%);
}

.tooltipContent {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 400;
  font-size: 1rem;
  padding: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(67 48 99 / var(--tw-bg-opacity));
  border-radius: 0.75rem;
}

.tooltipContent a {
  font-weight: 500;
  color: white;
}

.tooltip .spacing {
  height: 1.75rem;
}