.title {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 730;
    margin-bottom: 22px;
    @media (max-width: 640px) {
        font-size: 1.3rem;
    }
}

.listContent {
    position: relative;
    left: -35px;
    @media (max-width: 640px) {
        display: flex;
        justify-content: center;
    }
    @media (max-width: 800px) {
        left: 0;
    }
}

.help {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 1rem;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-size: 1.125rem;
    font-weight: 700;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
        color: var(--white);
        background-color: var(--primary);
    }
}
