.labelWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    & > label {
        font-size: 1.2em;
    }
}

.pl .labelWrap {
    gap: .7rem;
}