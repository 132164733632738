.error400 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    margin-top: 15%;

    @media only screen and (max-width: 768px) {
        text-align: center;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}

.error400 .image {
    width: 45%;
    height: auto;
    text-align: right;

    @media only screen and (max-width: 768px) {
        width: 90%;
        text-align: center;
    }
}

.error400 .image img {
    width: 100%;
    height: auto;
    max-width: 320px;
    max-height: 320px;

    @media only screen and (max-width: 768px) {
        max-width: 300px;
        max-height: 300px;
    }

    @media only screen and (max-width: 365px) {
    }
}

.error400 .content {
    width: 55%;
    text-align: left;

    @media only screen and (max-width: 768px) {
        width: 90%;
        text-align: center;
    }
}