.listWrap {
    counter-reset: list;
    line-height: 1.75;
    font-size: 1em;
    list-style-position: outside;
}

@media (max-width: 640px) {
    .listWrap {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.listItem {
    counter-increment: list;
    list-style: none;
    text-align: left;
    position: relative;
}

.listItem:before {
    position: relative;
    top: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    margin: 0px 13px 0px -40px;
    border-radius: 2rem;
    transform: translateY(-0.15rem);
    font-weight: 750;
    color: white;
    background-color: var(--primary);
    content: counter(list) '';
}

@media (max-width: 640px) {
    .listItem:before {
        margin-right: 0.7rem;
    }
}

.listItem:not(:last-child) {
    margin-bottom: 1rem;
}

&:not(:last-child) {
    .pl .listItem {
        margin-bottom: 2rem;
    }
}