:root {
    --gray-dim: #777777;
    --orange: #f48965;
    --purple-light: #ebd7f9;
    --purple-medium: #d8c6e5;
    --purple-medium2: #ccbde5;
    --purple-opacity: hsla(276, 75%, 61%, 0.5);
    --purple-lightest: #f1e3fb;
    --orange-dark: #e76060;
    --orange-light: #f7b7b7;
    --gray-light: #f7f6f9;
    --gray-medium: #c0c0c0;
    --gray-dark: #484747;
    --purple: #a94fe6;
    --purple-dark: #433063;
    --primary: var(--purple);
    --white: #fff;

    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}

*:disabled {
    all: unset
}

body {
    margin: 0;
    color: var(--purple-dark);
    line-height: 1.4;
    font-family:
        Open Sans,
        sans-serif !important;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--gray-light);
}

body .react-select__control {
    min-height: 3rem;
    color: var(--primary);
    padding-right: 0.25rem;
    border-radius: 999px;
    border: 2px solid var(--primary);
    overflow: hidden;
    cursor: pointer;
}
body .react-select__control:hover {
    border-color: var(--primary);
}
body .react-select__menu {
    padding: 1rem 0;
    border-radius: 1.25rem;
    overflow: hidden;
    background-color: var(--gray-light);
}
body .react-select__control--is-focused {
    box-shadow: none;
}
body .react-select__control--is-hovered {
    box-shadow: none;
}
body .react-select__control--is-disabled {
    border: 2px solid rgb(222,216,222) !important;
    background-color: rgb(245,238,249) !important;
}
body .react-select__single-value {
    color: var(--purple-dark);
}
body .react-select__indicator {
    padding: 0.3125rem;
    border-radius: 999px;
    color: var(--white);
    background-color: var(--primary);
    margin-right: 3px;
}
body .react-select__indicator svg {
    width: 1.25rem;
    height: 1.25rem;
}
body .react-select__control--is-disabled .react-select__indicator {
    display: none;
}
body .react-select__single-value--is-disabled {
    /* color: rgb(226,219,230) !important; */
    /* color: #777777 !important; */
    color: rgb(119,119,119) !important;
}
body .react-select__option {
    padding: 0.25rem 1rem;
    color: var(--primary);
    background-color: transparent;
    cursor: pointer;
}
body .react-select__option:hover {
    color: var(--white);
    background-color: var(--primary);
}
body .react-select__indicator-separator {
    display: none;
}
body .react-select__value-container {
    padding: 0 0 0 1rem;
}
body .react-select__placeholder {
    color: var(--purple-dark);
}
body .react-select__control {
    padding: 0.27rem;
    font-size: 1.125rem;
}
body .react-select__menu-portal {
    z-index: 901 !important;
}
body .react-select__menu-portal > div {
    border: 2px solid var(--primary);
}
body .react-select__option {
    padding: 6px 20px !important;
}


html {
    font-size: 85%;
}

.pl * {
    font-family: Poppins, arial, 'sans-serif';
}

.pl {
    font-size: 1.1rem;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.overflow-auto {
    overflow: auto;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.columnWithGap {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.mobileMargin {
    @media (max-width: 640px) {
        margin-top: 50px;
    }
}

label {
    font-size: 1.2em;
}

/* Date picker from react-date-picker library */

.react-date-picker__wrapper {
    /* 41px height (odd number, for better centering), 16px base height, 85% page view zoom */
    /*  41 / (16 * 0.85) = 3.0147058824 nice number, it was not my idea to set 85% */
    height: 3.0147058824rem !important;
    font-size: 1.125rem !important;
    padding: 0 1rem;
    border: 2px solid var(--primary) !important;
    border-radius: 999px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-self: center;
    max-width: 10rem;
    margin: 0 auto;
}

.react-date-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__input {
    display: inline-block !important;
    color: var(--gray-dark) !important;
    font-weight: 600 !important;
    border: 0;
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    background: none;
    font: inherit;
    box-sizing: content-box;
    outline: none;
}

.react-date-picker__inputGroup__input::placeholder {
    font-weight: 400 !important;
}

.react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
}

.react-date-picker__button {
    display: none !important;
}

.react-date-picker__inputGroup__divider {
    min-width: 0.54em;
    display: inline-block;
    text-indent: -9999px;
    position: relative;

    &:after {
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background: var(--primary);
        transform: rotate(15deg) translateX(-4px) translateY(-50%);
        content: '';
    }
}

.nestedUlStyles {
    margin: 30px 0;
    list-style: outside;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.gap {
    gap: 50px;
}
.gap-4 {
    gap: 1rem;
}

.hidden {
    display: none;
}

.container {
    margin: auto;
    max-width: calc(100% - 30px);

    @media only screen and (min-width: 768px) {
        max-width: 738px;
    }

    @media only screen and (min-width: 1024px) {
        max-width: 994px;
    }
}

.fixed {
    position: fixed;
}

.left-1\/2 {
    left: 50%;
}

.top-1\/2 {
    top: 50%;
}
.top-\[-16px\] {
    top: -16px;
}

.right-\[-16px\] {
    right: -16px;
}

.block {
    display: block;
}

.grid {
    display: grid;
    grid-template-columns: none;
    gap: 30px;
    margin-top: 30px;
}

.pl .grid {
    column-gap: 4rem;
    row-gap: 3rem;
}

.grid-3-columns {
    grid-template-columns: none;
}

.grid:not(:first-of-type) {
    margin-top: 30px;
}

.grid > * {
    width: 50%;
}

.grid-full {
    grid-template-columns: none;
}

.grid-full > * {
    width: 100%;
}

h2 {
    font-size: 28px;
    font-weight: 700;
}

h3 {
    font-size: 22px;
    font-weight: 500;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.top-0 {
    top: 0;
}
.top-4 {
    top: 1rem;
}
.right-0 {
    right: 0;
}
.left-0 {
    left: 0;
}
.left-4 {
    left: 1rem;
}
.bottom-0 {
    bottom: 0;
}

.block {
    display: block;
}
.block-force {
    display: block !important;
}
.none {
    display: none;
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
}

.-translate-y-1\/2 {
    --tw-translate-y: -50%;
}

.-translate-x-1\/2, .-translate-y-1\/2 {
    -webkit-transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.items-center {
    align-items: center;
}

.items-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}
.text-center-force {
    text-align: center !important;
}

.text-base {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
}
.text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
}
.text-xl {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
}

.font-medium {
    font-weight: 600;
}

.color-purple-dark {
    color: var(--purple-dark);
}

.gap-x-2 {
    -webkit-column-gap: .5rem;
    column-gap: .5rem;
}

.m-auto {
    margin: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.m-0 {
    margin: 0;
}

.mt-0-force {
    margin-top: 0 !important;
}

.mt-5 {
    margin-top: 5px;
}

.mt-2\.5 {
    margin-top: 0.625rem;
}

.mt-2\.5-force {
    margin-top: 0.625rem !important;
}

.mt-8 {
    margin-top: 2rem;
}

.mt-8-force {
    margin-top: 2rem !important;
}

.mt-50 {
    margin-top: 50px;
}

.mt-50-force {
    margin-top: 50px !important;
}

.mt-96 {
    margin-top: 24rem;
}

.ml-auto {
    margin-left: auto;
}
.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.mt-\[30\%\] {
    margin-top: 30%;
}
.mt-\[40\%\] {
    margin-top: 40%;
}
.mt-\[50\%\] {
    margin-top: 50%;
}

.mb-8 {
    margin-bottom: 2rem;
}
.mb-10 {
    margin-bottom: 2.5rem;
}

.p-4 {
    padding: 1rem;
}
.p-8 {
    padding: 2rem;
}
.p-24 {
    padding: 24px;
}
.p-10 {
    padding: 2.5rem;
}

.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-4 {
    padding-bottom: 1rem;
}

.pr-2 {
    padding-right: 0.5rem;
}
.pr-4 {
    padding-right: 1rem;
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.min-w-8 {
    min-width: 2rem;
}
.w-full {
    width: 100%;
}

.w-fit-content {
    width: fit-content;
}

.w-\[calc\(100\%-2rem\)\] {
    width: calc(100% - 2rem);
}
.w-\[calc\(100\%-8rem\)\] {
    width: calc(100% - 8rem);
}
.w-\[calc\(100\%-12rem\)\] {
    width: calc(100% - 12rem);
}

.w-\[8\.125rem\] {
    width: 8.125rem;
}

.w-\[14rem\] {
    width: 14rem;
}

.max-w-sm {
    max-width: 37.5rem;
}

.max-w-32 {
    max-width: 8rem;
}

.max-w-48 {
    max-width: 12rem;
}

.max-w-md2 {
    max-width: 70rem;
}

.max-w-\[14rem\] {
    max-width: 14rem;
}
.max-w-\[24rem\] {
    max-width: 24rem;
}

.min-h-8 {
    min-height: 2rem;
}

.max-h-5\/6 {
    max-height: 83.333333%;
}

.max-h-full {
    max-height: 100%;
}

.space-between {
    justify-content: space-between;
}

.z-50 {
    z-index: 50;
}

.z-60 {
    z-index: 60;
}

.z-100 {
    z-index: 100;
}

.input-hidden {
    cursor: pointer;
    display: block;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
}

.pointer {
    cursor: pointer;
}

.divider {
    margin-top: 30px;
    justify-content: center;
    flex-direction: column;
    border: 0;
    border-bottom: 1px;

}

.rounded-sm {
    border-radius: 1.25rem;
}
.rounded-lg {
    border-radius: 1.5rem;
}
.rounded-t-xs {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
}

.shadow-popup {
    --tw-shadow: 0px 2px 10px #536093;
    --tw-shadow-colored: 0px 2px 10px var(--tw-shadow-color);
}

.shadow, .shadow-popup {
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.border-purple {
    --tw-border-opacity: 1;
    border-color: rgb(169 79 230/var(--tw-border-opacity));
    border-bottom-width: 1px;
    border-style: solid;
}

.\!border-gray {
    border: 2px solid var(--gray-medium) !important;
}

.opacity-70 {
    opacity: .7;
}
.opacity-80 {
    opacity: .8;
}
.opacity-90 {
    opacity: .9;
}

.bg-yellow {
    --tw-bg-opacity: 1;
    background-color: rgb(253 243 231/var(--tw-bg-opacity));
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.bg-white-force {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity)) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0.20);
}

.bg-lightPurple {
    --tw-bg-opacity: 1;
    background-color: rgb(242 233 248 / var(--tw-bg-opacity));
}
.bg-orangeLight {
    --tw-bg-opacity: 1;
    background-color: rgb(247 183 183 / var(--tw-bg-opacity));
}
.\!bg-gray {
    background-color: var(--gray-light) !important;
}

.text-darkPurple {
    --tw-text-opacity: 1;
    color: rgb(67 48 99/var(--tw-text-opacity));
}

.switch {
    align-items: center;
    cursor: pointer;
    display: flex;
    /* font-size: .875rem; */
    justify-content: flex-start;
    min-height: 2rem;
    padding-left: 4.25rem;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}
.input-hidden:checked ~ .switch__mark {
  --tw-bg-opacity: 1;
  background-color: rgb(169 79 230/var(--tw-bg-opacity));
}
.input-hidden:checked ~ .switch__mark::after {
  --tw-translate-x: 1.25rem;
  -webkit-transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.switch__mark, .switch__mark::after {
    --tw-bg-opacity: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
}
.switch__mark {
    --tw-border-opacity: 1;
    background-color: rgb(242 242 242/var(--tw-bg-opacity));
    border-color: rgb(169 79 230/var(--tw-border-opacity));
    border-radius: 999px;
    border-width: 2px;
    border-style: solid;
    height: 2rem;
    left: 0;
    top: 0;
    transition-duration: .15s;
    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    width: 3.25rem;
    z-index: 0;
}
.switch__mark::after {
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    --tw-shadow: 0px 1px 4px rgba(67,48,99,.34);
    --tw-shadow-colored: 0px 1px 4px var(--tw-shadow-color);
    --tw-content: "";
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-radius: 999px;
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    color: rgb(255 255 255/var(--tw-text-opacity));
    content: var(--tw-content);
    font-size: 1rem;
    font-weight: 700;
    height: 1.5rem;
    left: .2rem;
    top: .1rem;
    transition-duration: .15s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    width: 1.5rem;
    margin: 2px;
    z-index: 10;
  }

@media (min-width: 40em) {
    .grid {
        grid-template-columns: auto auto;
        margin-top: 0;
    }
    .grid-3-columns {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .grid-full {
        grid-template-columns: none;
    }
    .md\:justify-center {
        justify-content: center;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:flex {
        display: flex;
    }
    .md\:py-12 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }
    .md\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .md\:max-w-\[24rem\] {
        max-width: 24rem;
    }
}

@media (min-width: 30em) {
    .sm\:justify-center {
        justify-content: center;
    }
    .sm\:items-center {
        align-items: center;
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:flex {
        display: flex;
    }
    .sm\:py-12 {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }
    .sm\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .sm\:max-w-\[24rem\] {
        max-width: 24rem;
    }
}



.pl .loaderWrap {
    display: inline-block;
    position: relative;
}
.pl .loaderWrap.purple {
    color: var(--primary);
}
.pl .loaderWrap.white {
    color: var(--white);
}
.pl .loaderWrap div {
    display: inline-block;
    position: absolute;
    left: 10%;
    width: 20%;
    background: currentColor;
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.pl .loaderWrap div:nth-child(1) {
    left: 10%;
    animation-delay: -0.24s;
}
.pl .loaderWrap div:nth-child(2) {
    left: 40%;
    animation-delay: -0.12s;
}
.pl .loaderWrap div:nth-child(3) {
    left: 70%;
    animation-delay: 0;
}